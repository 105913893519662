import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./contact.scss";
import emailjs from "emailjs-com";
import Contactinfo from "./contactInfo/contactinfo";
// import ContactSocial from "./contactInfo/contactSocial";
import Modal from "../contact-modal/Modal";
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import PageHeader from "../../components/ui-components/page-header/pageheader";
import ContactBackground from "../../assets/contact/contactUs.gif";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sending: false,
      successModal: false,
      errorModal: false,
    };
  }

  // Handling input changes
  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Handling form submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ sending: true });

    const { name, email, message } = this.state;

    const templateParams = {
      name: name,
      email: email,
      message: message,
    };

    // EmailJS configuration
    const SERVICE_ID = "service_kaeohqb";
    const TEMPLATE_ID = "template_awr36af";
    const USER_ID = "fcJLNr3o-UjAoyCdW";

    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then((response) => {
        console.log("Email sent successfully!", response);
        if (response.status === 200) {
          this.showSuccessModal();
        } else {
          this.showErrorModal();
        }
      })
      .catch((error) => {
        console.error("EmailJS Error:", error);
        this.showErrorModal();
      });
  };

  // Show success modal
  showSuccessModal = () => {
    this.setState({ successModal: true, sending: false });
    this.resetForm();
  };

  // Show error modal
  showErrorModal = () => {
    this.setState({ errorModal: true, sending: false });
    this.resetForm();
  };

  // Reset the contact form
  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
    });
  };

  // Close all modals
  closeModal = () => {
    this.setState({ successModal: false, errorModal: false });
  };

  render() {
    const { sending, successModal, errorModal } = this.state;

    let submitButtonRender = (
      <div className="">
        <AwesomeButton type="primary" className="">
          Send Message
        </AwesomeButton>
      </div>
    );

    if (sending) {
      submitButtonRender = (
        <div className="small__button sending-btn">
          <div className="flex-center">
            <div className="sbl-circ"></div>
          </div>
        </div>
      );
    }

    let modalRender = null;
    if (successModal) {
      modalRender = <Modal closeModal={this.closeModal} status="success" />;
    } else if (errorModal) {
      modalRender = <Modal closeModal={this.closeModal} status="error" />;
    }

    return (
      <>
        <div className="container-flex">
          <PageHeader className="contactPageheader" titleSen="Let's Talk" />
        </div>
        {modalRender}
        <div className="container">
          <Row className="padding40">
            <Col md={12} lg={6}>
              <form id="contact-form" onSubmit={this.handleSubmit}>
                <h4 className="font30 weight800 padding30">Send Us Message.</h4>
                <input
                  type="text"
                  placeholder="Name"
                  required
                  name="name"
                  value={this.state.name}
                  onChange={this.inputHandler}
                />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={this.state.email}
                  onChange={this.inputHandler}
                />
                <textarea
                  rows="6"
                  cols="50"
                  placeholder="Message..."
                  required
                  name="message"
                  value={this.state.message}
                  onChange={this.inputHandler}
                ></textarea>
                {submitButtonRender}
              </form>
            </Col>
            <Col md={12} lg={6}>
              <div className="flex-center">
                <img src={ContactBackground} alt="contact background" />
              </div>
            </Col>
          </Row>
          <Contactinfo />
          {/* <ContactSocial /> */}
        </div>
      </>
    );
  }
}

export default Contact;
