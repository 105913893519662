import React from "react";
import videoBg from "../../assets/banner/Banner.mp4";
import "../banner/Banner.scss";
import { TypeAnimation } from 'react-type-animation';

const Banner = () => {
  return (
    <div>
      <video width="600" loop muted autoPlay playsInline crossOrigin="anonymous">
        <source src={videoBg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay">
        <h3>We Make Your</h3>
        <h1>Brand Stand Out</h1>
        <div className="type-animation">
          <span className="static-text">We provide services in </span>
          <TypeAnimation
            sequence={[
              'Digital Marketing',
              1000,
              'Branding',
              1000,
              'Graphic Design',
              1000,
              'Visual Ads',
              1000,
              'Web Design and Development',
              1000,
              'SEO',
              1000,
              'Theatre',
              1000,
              'FM',
              1000,
              'Newspaper Ads',
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{ display: 'inline-block' }}
            repeat={Infinity}
            className="changing-text"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
