import React from 'react';
import './portfolios.scss';
import Title from '../ui-components/title/title';
import { Link } from 'react-router-dom';
import { TiArrowUp  } from "react-icons/ti";
import { AwesomeButton } from "react-awesome-button";
import print from "../../assets/portfolio/print.jpg"
import visual from "../../assets/portfolio/visual.jpg"
import social from "../../assets/portfolio/social.jpg"
const cardDataset = [
    {
        image: print,
        title: "Print Ads",
        button: <TiArrowUp className="rotated-icon"/>
    },
    {
        image: visual,
        title: "Visual Ads",
        button:  <TiArrowUp className="rotated-icon"/>
    },
    {
        image: social,
        title: "Social Media",
        button:  <TiArrowUp className="rotated-icon" />
    },
];

const Portfolios = () => {
  return (
    <>
      <div className="container">
        <Title title="Work Showcase." />
        <div className="showcase">
          {cardDataset.map((item, index) => (
            <div className="card-port" key={index}>
              <Link to="/portfolioinner">
              <img src={item.image} alt="Card image cap" className="cardimge" /></Link>
              <div className="overlay-contents">
                <Link to="/portfolioinner">
                <button>{item.button}</button>
                </Link>
              </div>
              <div>
              <h2 className='Card_title'>{item.title}</h2>
              </div>
            </div>
            
          ))}
        </div>
      </div>
    </>
  );
};

export default Portfolios;
