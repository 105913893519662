import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { Link } from "react-scroll";
import "./footer.scss";
import { FaFacebook, FaYoutube, FaInstagram, FaLinkedin } from 'react-icons/fa';

import Logo from '../../assets/navbar/logo (1).svg';
import Arrow from '../../assets/footer/arrow.svg';

const PartnerBox = () => (
  <div className="footer">
    <div className="wrapper">
      <Row>
        {/* Column 1: Logo and Social Media */}
        <Col xs={12} sm={4} md={4}>
          <div className="footer-box logo-social">
            <img src={Logo} alt="logo" width={120} />
            <div className="social-icons">
              <a href="https://www.facebook.com/winscreatives1" target="_blank" rel="noopener noreferrer" className="facebook">
                <FaFacebook size={20} />
              </a>
              <a href="www.youtube.com/@winscreatives" target="_blank" rel="noopener noreferrer" className="youtube">
                <FaYoutube size={20} />
              </a>
              <a href="https://www.instagram.com/winscreatives/" target="_blank" rel="noopener noreferrer" className="instagram">
                <FaInstagram size={20} />
              </a>
              <a href="https://www.linkedin.com/company/wins-creatives" target="_blank" rel="noopener noreferrer" className="linkedin">
                <FaLinkedin size={20} />
              </a>
            </div>
          </div>
        </Col>

        {/* Column 2: Text */}
        <Col xs={12} sm={4} md={4}>
          <div className="footer-box">
            <p>© 2024 - Crafted with ❤️ by WinsCreatives, All Rights Reserved</p>
          </div>
        </Col>

        {/* Column 3: Back to Top */}
        <Col xs={12} sm={4} md={4}>
          <Link to="nav" spy={true} smooth={true} offset={0} duration={500}>
            <div className="footer-box back-to-top">
              <p>BACK TO TOP</p>
              <img src={Arrow} alt="arrow" />
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  </div>
);

export default PartnerBox;
