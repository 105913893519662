import React from 'react';
import Banner from '../../../assets/portfolio/inner-banner.png';
import './pageheader.css';

const PageHeader = (props) => {
  return (
    <div className="container-flex">
      <div className="background">
        <img src={Banner} alt="Banner" className="Banner_img" />
        <div className="titleW">
          <div className="container">
            <p className={`TitleM ${props.className}`}>
              {props.titleSen} <br /> {props.titleFir}
            </p>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
