// src/components/ui-components/Preloader.jsx
import React, { useEffect, useState } from 'react';
import './preloader.scss';
import PreImg from "../../../assets/cursor/logo (1).svg"

const Preloader = ({ onLoaded }) => {
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const timer = setTimeout(() => {
        setLoading(false);
        if (onLoaded) onLoaded();
      }, 2500); // 
  
      return () => clearTimeout(timer);
    }, [onLoaded]);
  
    if (!loading) return null;
  
    return (
      <div className="preloader">
        <div className="preloader-content">
          <img src={PreImg} alt="Loading..." />
        </div>
      </div>
    );
  };
  
  export default Preloader;
