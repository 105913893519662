import React, { useRef, useState } from "react";
import "./services.scss";
import { NavLink } from "react-router-dom";
import Title from "../ui-components/title/title.jsx";
import "bootstrap-icons/font/bootstrap-icons.css";

const Services = () => {
  const containerRef = useRef(null); // Reference for the container
  const [selectedService, setSelectedService] = useState(null); // State to track the clicked service


  // Add more detailed information to each service
  const servicesData = [
    {
      icon: "bi-meta",
      title: "Digital Marketing",
      description: "Connect with your audience through customized digital marketing for optimal results",
      details: "Use digital media to connect with your target market. We provide customized digital marketing plans that maximize return on investment and produce results, ranging from email marketing to content marketing.",

     
    },
    {
      icon: "bi-box2-heart",
      title: "Branding",
      description: "We create captivating brand identities that deeply connect and impact audiences.",
      details: "Our expertise lies in creating captivating brand identities that effectively connect with your intended audience, creating a deep impact, and encouraging brand.",
      more:"learn more >",
      
      
    },
    {
      icon: "bi-brush",
      title: "Graphic Design",
      description: "Our graphic design team creates captivating, original, and consistent brand visuals.",
      details: "Our expertise lies in creating captivating brand identities that effectively connect with your intended audience, creating a deep impact, and encouraging brand.",
      more:"learn more >",
      
      
    },
    {
      icon: "bi-badge-ad-fill",
      title: "Visual ads",
      description: "We create captivating brand identities that deeply connect and impact audiences.",
      details: "Use eye-catching visual and print ads to make a lasting impact. We design advertisements that effectively communicate your brand message and draw attention, whether they are on a billboard, magazine, or digital display.",
      
      
    },
    {
      icon: "bi-code-slash",
      title: "Web Design & Development",
      description: "We build responsive, user-friendly websites that enhance engagement and conversions.",
      details: "For many people, your website serves as their initial point of contact with you. Our skilled team creates user-friendly, responsive websites that look fantastic and increase engagement and conversions.",
      
      
    },
    {
      icon: "bi-globe",
      title: "Seo",
      description: "We optimize your website for higher search rankings and quality leads.",
      details: "We improve your website to appear higher in search engine rankings, giving your company more exposure and drawing in quality prospects.",
      
      
    },
    // Add more services as needed
  ];

  // Scroll left function
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  // Scroll right function
  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  // Open modal when a service is clicked
  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  // Close modal
  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <>
      <section className="Service-section">
        <div className="container Service-Title">
          <Title title="Services." className="Service-heading" />
        </div>
        <div className="container">
          <div className="scroll-container" ref={containerRef}>
            {servicesData.map((service, index) => (
              <div
                className="Service-box"
                key={index}
                onClick={() => handleCardClick(service)}
              >
                {/* Conditionally render icon */}
                {service.icon.startsWith("bi-") ? (
                  <i className={`bi ${service.icon}`} style={{ fontSize: '2.5em' }}></i>
                ) : (
                  <img src={service.icon} alt={`${service.title} Icon`} />
                )}
                <div className="Service-content">
                  <h3 className="srv-title">{service.title}</h3>
                  <p className="srv-desc">{service.description}</p>
                </div>
                <div className="cross">
                  <i className="bi bi-plus-lg"></i>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="scroll-buttons">
          <button className="scroll-button left" onClick={scrollLeft}>
            <i className="bi bi-chevron-left"></i>
          </button>
          <button className="scroll-button right" onClick={scrollRight}>
            <i className="bi bi-chevron-right"></i>
          </button>
        </div>
      </section>

      {/* Modal */}
      {selectedService && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={closeModal}>
            <i class="bi bi-x-lg"></i>
            </button>
            {/* Display service details in the modal */}
            <p className="modal-description">{selectedService.description}</p>
            <h3 className="modal-title">{selectedService.title}</h3>
            <p className="modal-details"> {selectedService.details}</p>
            <NavLink to="/portfolioinner">
            <p className="model-link">{selectedService.more}</p>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default Services;
